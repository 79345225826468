import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const STEPS = {
	first: 1,
	second: 2,
	third: 3,
};

export const initialState = {
	gtvOffer: {},
	abilities: {},
	tradeDecisionModal: {
		activeStep: STEPS.first,
		modalTitle: 'Trade options',
		agreedTerms: false,
		agreedTermsError: null,
		billOfSale: null,
		billOfSaleError: null,
	},
};

export const gtvOfferShowSlice = createSlice({
	name: 'gtvOfferShow',
	initialState,
	reducers: {
		setGtvOffer: (state, { payload }) => {
			state.gtvOffer = payload.gtvOffer;
		},
		setAbilities: (state, { payload }) => {
			state.abilities = payload.abilities;
		},
		setTradeDecisionModalTitle: (state, { payload }) => {
			state.tradeDecisionModal.modalTitle = payload.title;
		},
		setTradeDecisionModalActiveStep: (state, { payload }) => {
			state.tradeDecisionModal.activeStep = payload.step;
		},
		resetModal: (state) => {
			state.tradeDecisionModal = initialState.tradeDecisionModal;
		},
		resetModalTitle: (state) => {
			state.tradeDecisionModal.modalTitle = initialState.tradeDecisionModal.modalTitle;
		},
		setAgreedTerms: (state, { payload }) => {
			state.tradeDecisionModal.agreedTerms = payload.agreed;
		},
		setAgreedTermsError: (state, { payload }) => {
			state.tradeDecisionModal.agreedTermsError = payload.error;
		},
		setBillOfSale: (state, { payload }) => {
			state.tradeDecisionModal.billOfSale = payload.file;
		},
		setBillOfSaleError: (state, { payload }) => {
			state.tradeDecisionModal.billOfSaleError = payload.error;
		},
		setBillOfSaleAttributes: (state, { payload }) => {
			state.gtvOffer.bill_of_sale_name = payload.file.name;
			state.gtvOffer.bill_of_sale_url = URL.createObjectURL(payload.file);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			return {
				...state,
				abilities: {
					...state.abilities,
					...action.payload.gtvOfferShow?.abilities,
				},
				gtvOffer: {
					...state.gtvOffer,
					...action.payload.gtvOfferShow?.gtvOffer,
				},
			};
		});
	},
});

export const {
	setGtvOffer,
	setAbilities,
	setTradeDecisionModalTitle,
	setTradeDecisionModalActiveStep,
	resetModal,
	resetModalTitle,
	setAgreedTerms,
	setAgreedTermsError,
	setBillOfSaleAttributes,
	setBillOfSale,
	setBillOfSaleError,
} = gtvOfferShowSlice.actions;

export default gtvOfferShowSlice.reducer;
