import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
	treatments: {},
};

export const SLICE_NAME = 'treatments';

export const treatmentsSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {
		setTreatments: (state, { payload: { treatments } }) => {
			state.treatments = { ...state.user, ...treatments };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			// just merge the data on hydrate
			return {
				...state,
				...action.payload[SLICE_NAME],
				treatments: {
					...state.user,
					...action.payload[SLICE_NAME].treatments,
				},
			};
		});
	},
});

export const { setTreatments } = treatmentsSlice.actions;

export default treatmentsSlice.reducer;
